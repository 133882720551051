<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习成绩查看</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="h-contain">
        <div class="h-title">{{ infoData.practice_plan_name }}</div>
        <div class="l-item">
          <!-- <el-image class="elimage" :src="icon1" /> -->
          <div class="title">实习形式：{{ infoData.type | typeformat }}</div>
        </div>
        <div class="l-item">
          <!-- <el-image class="elimage" :src="icon2" /> -->
          <div class="title">
            实习时间： {{ infoData.start_date | dataformat }} ~
            {{ infoData.end_date | dataformat }}
          </div>
        </div>
        <div class="score-box" v-if="infoData.score">
          <div>成绩</div>
          <div style="font-size: 40px">{{ infoData.score }}</div>
        </div>
      </div>

      <div class="b-contain">
        <div class="head-t">
          <div class="h-item">
            <div class="text">签到记录</div>
          </div>
          <div class="h-item">
            <div class="text2">应签到:</div>
            <div class="text2">{{ checkinInfo.attendance_count }}</div>
          </div>
          <div class="h-item">
            <div class="text2">累计签到:</div>
            <div class="text2">{{ checkinInfo.attendance_1 }}</div>
          </div>
          <div class="h-item">
            <div class="text2">未签到:</div>
            <div class="text2">{{ checkinInfo.attendance_0 }}</div>
          </div>
          <div class="h-item">
            <div class="text2">外勤签到:</div>
            <div class="text2">{{ checkinInfo.attendance_2 }}</div>
          </div>
          <div class="h-item">
            <div class="text2">签到异常:</div>
            <div class="text2">{{ checkinInfo.attendance_3 }}</div>
          </div>
        </div>
        <div class="searchcontain">
          <div class="left">
            <div class="contain">
              <div class="maintext">签到日期:</div>
              <el-date-picker class="elinput" v-model="timevalue" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"
                @change="changeDate"></el-date-picker>
            </div>
            <div class="contain">
              <div class="maintext">状态:</div>
              <el-select class="elinput" v-model="queryParams.status" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="right">
            <el-button type="primary" @click="searchbt" style="background: #3d84ff">查询</el-button>
            <el-button type="info" @click="resetbt">重置</el-button>
            <!-- <div class="bt searchbt" @click="searchbt">查询</div>
            <div class="bt restbt" @click="resetbt">重置</div>-->
          </div>
        </div>
        <div class="main-contain">
          <div class="contain">
            <el-table ref="singleTable" :data="checkinTable" highlight-current-row style="width: 100%" class="Table"
              :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }" :row-style="{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }">
              <el-table-column prop="date" label="考勤日期">
                <template slot-scope="{ row }">{{ row.date | dataformat }}</template>
              </el-table-column>
              <el-table-column prop="start_time" label="签到时间">
                <template slot-scope="{ row }">{{ row.start_time | timeformat }}</template>
              </el-table-column>
              <el-table-column prop="address" label="签到地点"></el-table-column>
              <el-table-column prop="status" label="签到状态">
                <template slot-scope="{ row }">{{ row.status | statusformap }}</template>
              </el-table-column>
            </el-table>
            <div class="elpagination">
              <div class="elpagination-title">共{{ total }}条</div>
              <el-pagination background layout="prev, pager, next" :total="total" :page-size="queryParams.per_page" pager-count="5"
                :current-page="queryParams.page" @current-change="pageChange"></el-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="bb-contain">
        <div class="head-t">
          <div class="h-item">
            <div class="text">日志记录</div>
          </div>
        </div>
        <el-tabs class="eltabs" v-model="activeName">
          <el-tab-pane label="日报" name="0">
            <Journals :log_type="activeName" v-if="activeName == 0" :practice_plan_id="queryParams.practice_plan_id" />
          </el-tab-pane>
          <el-tab-pane label="周报" name="1">
            <Journals :log_type="activeName" v-if="activeName == 1" :practice_plan_id="queryParams.practice_plan_id" />
          </el-tab-pane>
          <el-tab-pane label="月报" name="2">
            <Journals :log_type="activeName" v-if="activeName == 2" :practice_plan_id="queryParams.practice_plan_id" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="f-contain" v-if="infoData.report_commit_start_date">
        <div class="f-title">实习报告</div>
        <div class="contain">
          <div class="left">
            <div class="f-title2">{{ infoData.practice_plan_name }}</div>
            <div class="f-icon">
              <el-image class="elimage" :src="icon3" />
              <div class="f-title3">
                要求提交时间：{{
        infoData.report_commit_start_date | dataformat
      }}
                ~ {{ infoData.report_commit_end_date | dataformat }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="text">评分：{{ infoData.total_score }}</div>
            <div class="text2" @click="handelClick">查看</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import {
  getpracticeattendancelist,
  getpracticeattendanceinfo,
  getpracticeinfo,
} from "@/api/practiceresult"
import Journals from "./journals"

const typemap = ["全部", "自主报名", "集中安排"]
const statusmap = ["未签到", "已签到", "外勤签到", "签到异常", "请假"]

export default {
  name: "mainpage",
  components: {
    Journals,
  },
  filters: {
    dataformat(value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
    timeformat(value) {
      if (!value) return ""
      return dayjs.unix(value).format("HH:mm")
    },
    typeformat(value) {
      return typemap[value]
    },
    statusformap(value) {
      return statusmap[value]
    }
  },
  data() {
    return {
      infoData: {}, // 实习详情
      checkinInfo: {}, // 签到详情
      checkinTable: [], // 签到列表
      queryParams: {
        practice_plan_id: "",
        start_time: "",
        end_time: "",
        status: "",
        page: 1,
        per_page: 10,
      },
      timevalue: "",
      options: [
        {
          value: 0,
          label: "未签到",
        },
        {
          value: 1,
          label: "已签到",
        },
        {
          value: 2,
          label: "外勤签到",
        },
        {
          value: 3,
          label: "签到异常",
        },
        {
          value: 4,
          label: "请假",
        },
      ],
      activeName: "0",
      total: 0,
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/数据 (1).png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      icon3: require("@/assets/coursedetails/43443.png"),
      student_id: "",
      practice_report_id: "",
    }
  },
  computed: {},
  created() {
    this.queryParams.practice_plan_id = this.$route.query.plan_id
    this.student_id = this.$route.query.student_id
    this.practice_report_id = this.$route.query.practice_report_id

    this.getInfo() //实习详情   
    this.getpracticeattendanceinfo() //签到记录   
    this.getpracticeattendancelist() //获取实习计划列表
  },
  methods: {
    //实习详情
    getInfo() {
      let params = { practice_plan_id: this.$route.query.plan_id }
      getpracticeinfo(params).then((res) => {
        if (res.code === 0) {
          this.infoData = res.data
        }
      })
    },
    // 查询
    searchbt() {
      this.getpracticeattendancelist()
    },
    // 重置
    resetbt() {
      this.timevalue = ""
      this.queryParams.start_time = ""
      this.queryParams.end_time = ""
      this.queryParams.status = ""
      this.queryParams.page = 1
      this.queryParams.per_page = 10
      this.getpracticeattendancelist()
    },
    // 返回
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      })
    },
    // 计算时间
    changeDate(date) {
      if (date && date.length > 0) {
        this.queryParams.start_time = date[0] / 1000
        this.queryParams.end_time = date[1] / 1000
      } else {
        this.queryParams.start_time = ""
        this.queryParams.end_time = ""
      }
    },
    //获取实习考勤汇总数据 只传plan_id
    getpracticeattendanceinfo() {
      const params = {
        practice_plan_id: this.queryParams.practice_plan_id,
      }
      getpracticeattendanceinfo(params)
        .then((response) => {
          if (response.code === 0) {
            this.checkinInfo = response.data
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    //获取实习计划列表
    getpracticeattendancelist() {
      let params = this.queryParams
      getpracticeattendancelist(params)
        .then((response) => {
          if (response.code === 0) {
            this.total = response.data.total
            this.checkinTable = response.data.data
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    // 分页-切换页面
    pageChange(data) {
      this.queryParams.page = data
      this.getpracticeattendancelist()
    },
    // 查看详情
    handelClick() {
      this.$router.push({
        path: "/home/personalcenter/internshipreportview",
        query: {
          type: "通过",
          practice_plan_id: this.queryParams.practice_plan_id,
          practice_report_id: this.practice_report_id
            ? this.practice_report_id
            : "",
          student_id: this.student_id ? this.student_id : "",
        },
      })
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    
    .head {
      height: 60px;
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      padding-left: 20px;
      background: #fff;
      border-bottom: 2px solid #cccccc;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }

      .retutnbt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .maincontain {
      // min-height: 1008px;
      border-radius: 4px;
      padding-bottom: 20px;

      .h-contain {
        min-height: 170px;
        background: #fff;
        box-sizing: border-box;
        margin-bottom: 10px;
        position: relative;
        padding-left: 10px;
        padding-top: 10px;

        .h-title {
          font-size: 25px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }

        .l-item {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .title {
            margin-left: 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }

        .score-box {
          color: #222222;
          font-size: 18px;
          // padding: 20px;
          position: absolute;
          top: 25px;
          right: 40px;

          div {
            text-align: center;
          }
        }
      }

      .b-contain {
        min-height: 384px;
        background: #fff;
        box-sizing: border-box;
        padding: 10px;

        .head-t {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .h-item {
            // display: flex;
            // justify-content: flex-start;
            // align-items: flex-end;
            margin-right: 8px;

            .text {
              margin-right: 10px;
              font-size: 20px;
              font-weight: bold;
              color: #222222;
            }

            .text2 {
              // margin-right: 10px;
              font-size: 14px;
              font-weight: 400;
              color: #7f7f7f;
              text-align: center;
            }
          }
        }

        .searchcontain {
          margin-top: 10px;
          // height: 56px;
          background: #fcfcfc;
          border: 1px solid #ececec;
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          padding: 15px;

          .left {
            // display: flex;
            // justify-content: flex-start;
            // align-items: center;

            .contain {
              margin-right: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 10px;

              .maintext {
                margin-right: 10px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                width: 30%;
              }

              .elinput {
                width: 300px;

                ::v-deep .el-input__suffix {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }

          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .bt {
              width: 64px;
              height: 38px;
              line-height: 38px;
              text-align: center;
              background: #ffffff;
              border: 1px solid #3d84ff;
              border-radius: 2px;
              user-select: none;
              cursor: pointer;
              margin-left: 10px;
            }

            .searchbt {
              background: #3d84ff;
              color: white;
            }
          }
        }

        .main-contain {
          margin-top: 40px;
          margin-left: 10px;
          margin-right: 10px;
          height: 100%;
          width: 100%;

          .elpagination {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .elpagination-title {
              margin-right: 4px;
            }
          }
        }
      }

      .bb-contain {
        margin-top: 20px;
        min-height: 384px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;

        .head-t {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .h-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 10px;

            .text {
              margin-right: 10px;
              font-size: 20px;
              font-weight: bold;
              color: #222222;
            }
          }
        }

        .eltabs {
          margin-top: 40px;

          ::v-deep .el-tabs__item {
            font-size: 20px;
            padding: 0px;
            width: 130px;
            text-align: center;
          }
          ::v-deep .el-tabs__active-bar{
             width: 130px;
          }

          .main-contain {
            margin-left: 10px;
            margin-right: 10px;
            height: 200px;
            width: 100%;
            position: relative;

            .contain {
              position: absolute;
              width: 100%;
              height: 100%;

              .Table {
                width: 100%;

                // border: 1px solid red;
                .item-left {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  // margin-left: 10px;
                  .title {
                    margin-left: 10px;
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }

                .item-middle {
                  .title {
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }

                .item-right {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .bt {
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #0773fc;
                    user-select: none;
                    cursor: pointer;
                  }

                  .line2 {
                    margin-right: 8px;
                    margin-left: 8px;
                    height: 14px;
                    border-right: 2px solid #cccccc;
                  }
                }
              }

              .elpagination {
                margin-top: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                .elpagination-title {
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }

      .f-contain {
        margin-top: 20px;
        height: 206px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;

        .f-title {
          font-size: 25px;

          font-weight: 400;
          color: #222222;
        }

        .contain {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            .f-title2 {
              margin-top: 40px;
              font-size: 20px;

              font-weight: 400;
              color: #222222;
            }

            .f-icon {
              margin-top: 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .f-title3 {
                margin-left: 10px;
                font-size: 20px;

                font-weight: 400;
                color: #666666;
              }
            }
          }

          .right {
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              margin-right: 40px;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }

            .text2 {
              margin-right: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #0773fc;
              user-select: none;
              cursor: pointer;
            }
          }
        }
      }

      .bts {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bt {
          margin-right: 10px;
          width: 88px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          background: #0773fc;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .mainpage {
    .head {
      height: 60px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      padding-left: 20px;
      background: #fff;
      border-bottom: 2px solid #cccccc;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }

      .retutnbt {
        margin-right: 40px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .maincontain {
      // min-height: 1008px;
      border-radius: 4px;
      padding-bottom: 20px;

      .h-contain {
        min-height: 170px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;
        margin-bottom: 20px;
        position: relative;

        .h-title {
          font-size: 25px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }

        .l-item {
          margin-top: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .title {
            margin-left: 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }

        .score-box {
          color: #222222;
          font-size: 18px;
          padding: 20px;
          position: absolute;
          top: 20px;
          right: 30px;

          div {
            text-align: center;
          }
        }
      }

      .b-contain {
        min-height: 384px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;

        .head-t {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .h-item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-right: 10px;

            .text {
              margin-right: 10px;
              font-size: 20px;
              font-weight: bold;
              color: #222222;
            }

            .text2 {
              margin-right: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #7f7f7f;
            }
          }
        }

        .searchcontain {
          margin-top: 20px;
          height: 56px;
          background: #fcfcfc;
          border: 1px solid #ececec;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 20px;
          padding-right: 20px;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .contain {
              margin-right: 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .maintext {
                margin-right: 10px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
              }

              .elinput {
                width: 300px;

                ::v-deep .el-input__suffix {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }

          .right {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .bt {
              width: 64px;
              height: 38px;
              line-height: 38px;
              text-align: center;
              background: #ffffff;
              border: 1px solid #3d84ff;
              border-radius: 2px;
              user-select: none;
              cursor: pointer;
              margin-left: 10px;
            }

            .searchbt {
              background: #3d84ff;
              color: white;
            }
          }
        }

        .main-contain {
          margin-top: 40px;
          margin-left: 10px;
          margin-right: 10px;
          height: 100%;
          width: 100%;

          .elpagination {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .elpagination-title {
              margin-right: 4px;
            }
          }
        }
      }

      .bb-contain {
        margin-top: 20px;
        min-height: 384px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;

        .head-t {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .h-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 10px;

            .text {
              margin-right: 10px;
              font-size: 20px;
              font-weight: bold;
              color: #222222;
            }
          }
        }

        .eltabs {
          margin-top: 40px;

          ::v-deep .el-tabs__item {
            font-size: 20px;
            padding: 0px;
            width: 150px;
            text-align: center;
          }

          .main-contain {
            margin-left: 10px;
            margin-right: 10px;
            height: 200px;
            width: 100%;
            position: relative;

            .contain {
              position: absolute;
              width: 100%;
              height: 100%;

              .Table {
                width: 100%;

                // border: 1px solid red;
                .item-left {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  // margin-left: 10px;
                  .title {
                    margin-left: 10px;
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }

                .item-middle {
                  .title {
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }

                .item-right {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .bt {
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #0773fc;
                    user-select: none;
                    cursor: pointer;
                  }

                  .line2 {
                    margin-right: 8px;
                    margin-left: 8px;
                    height: 14px;
                    border-right: 2px solid #cccccc;
                  }
                }
              }

              .elpagination {
                margin-top: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                .elpagination-title {
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }

      .f-contain {
        margin-top: 20px;
        height: 206px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;

        .f-title {
          font-size: 25px;

          font-weight: 400;
          color: #222222;
        }

        .contain {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            .f-title2 {
              margin-top: 40px;
              font-size: 20px;

              font-weight: 400;
              color: #222222;
            }

            .f-icon {
              margin-top: 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .f-title3 {
                margin-left: 10px;
                font-size: 20px;

                font-weight: 400;
                color: #666666;
              }
            }
          }

          .right {
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              margin-right: 40px;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }

            .text2 {
              margin-right: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #0773fc;
              user-select: none;
              cursor: pointer;
            }
          }
        }
      }

      .bts {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bt {
          margin-right: 10px;
          width: 88px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          background: #0773fc;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
