<template>
  <div class="contain-box">
    <div class="contain">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        class="Table"
        :header-cell-style="{ 'font-size': '15px', color: '#666666', 'font-weight': 'bold', background: '#F7F7F7', }"
        :row-style="{ 'font-size': '15px', color: '#222222', 'font-weight': '400', }"
      >
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="created_at" label="提交时间">
          <template slot-scope="{row}">{{row.created_at | dataformat}}</template>
        </el-table-column>
        <el-table-column prop="content" label="内容" show-overflow-tooltip>
          <template slot-scope="{row}">
            <div v-html="row.content" style="overflow:hidden; max-height: 50px"></div>
          </template>
        </el-table-column>
        <el-table-column prop="reply_content" label="评语"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt" @click="checkbt(scope.row)">查看</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :current-page="queryParams.page"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>

    <Customdialog ref="customdialog" type="max" width="50%" title="日志查看" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <Loglook ref="loglook"></Loglook>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { getpracticeloglist } from "@/api/practiceresult"
import dayjs from "dayjs"
import Customdialog from "@/components/customdialog.vue"
import Loglook from "./loglook"
export default {
  name: "journals",
  props: {
    log_type: {
      type: String | Number,
      default: 0,
    },
    practice_plan_id: {
      type: String | Number,
    },
  },
  components: {
    Customdialog,
    Loglook,
  },
  data () {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        practice_plan_id: this.practice_plan_id,
        type: this.log_type,
        page: 1,
        per_page: 10,
      },
    }
  },
  watch: {
    log_type: {
      handler (val) {
        this.queryParams.type = val
        this.init()
      },
      immediate: true
    }
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  methods: {
    init () {
      getpracticeloglist(this.queryParams).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.data.map((item) => {
            item.content = this.AutoCorrection(item.content)
            item.file = this.AutoCorrection(item.file)
            return item
          })
          this.total = res.data.total
        }
      })
    },
    // 循环修正数据与数据格式
    AutoCorrection (str) {
      const that = this
      let newStr = str.replaceAll(/&nbsp;/ig, "") //去除空格字符
      newStr = newStr.replace(/[\r\n]/g, "").trim() // 去与换行与前后空格
      newStr = newStr.replace(/\\/g, "") // 去除反斜杠
      newStr = newStr.replace(/\s+/g, " ") // 去除多余空格
      // newStr = newStr.replace(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig, ""); // 去掉多余 P 标签
      // 判断是否有图片，如果有图片
      let isHavePic = newStr.includes('<img')
      if (isHavePic) {
        // 正则过滤出图片
        newStr.replace(/<img.+?>/ig, function (matchImg, captureImg) {
          // console.log("正则匹配所有图片并获取相应下标==>",matchImg, captureImg);
          // newStr = newStr.replace(match,""); // 清除图片
          let resImgSrc = '' // 自定义变量接收图片
          matchImg.replace(/<img[^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchSrc, captureSrc) {
            // console.log("正则匹配该图片并获取相应路径==>",matchSrc, captureSrc);
            resImgSrc = captureSrc.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          // 在图片中插入点击事件并默认不展示
          let newImgStr = matchImg.replace(/<s?img[^>]*src=['"]([^'"]+)/gi, `<img src="${resImgSrc}" style="width:auto; height:20px; margin: 4px;"`)
          newStr = newStr.replace(matchImg, newImgStr)
        })
      }
      // 判断是否有视频，如果有视频
      let isHaveMove = newStr.includes('<video')
      if (isHaveMove) {
        // 正则过滤出视频
        newStr.replace(/(<video[\s\S]*?>)[\s\S]*?(<\/video>)/ig, function (matchVideo, captureVideo) {
          // console.log("正则匹配所有视频并获取相应下标==>",matchVideo, captureVideo);
          // newStr = newStr.replace(matchVideo,""); // 清空视频
          let resMoveurl = '' // 自定义变量接收视频
          matchVideo.replace(/<source [^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchUrl, captureUrl) {
            // console.log("正则匹配该视频并获取相应路径==>",matchUrl, captureUrl);
            resMoveurl = captureUrl.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          let newMoveStr = matchVideo.replace(/<s?video[\s\S]*?>[\s\S]*?<\/video>/gi, ``)
          newStr = newStr.replace(matchVideo, newMoveStr)
        })
      }
      return newStr
    },
    checkbt (row) {
      this.$refs.customdialog.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.loglook.initdata(row)
      })
    },
    changePage (data) {
      this.queryParams.page = data
      this.init()
    },
  },
};
</script>

<style scoped lang="scss">
.contain-box {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  width: 100%;
  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
  .bt {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0773fc;
    user-select: none;
    cursor: pointer;
  }
}
</style>
